import type { ArrayElement } from "@bay1/data";
import type { UserConnection } from "@bay1/sdk/generated/graphql";
import { UserRole, Maybe } from "@bay1/sdk/generated/graphql";

export type UserNode = ArrayElement<UserConnection["edges"]>["node"] & {
  __typename?: string;
};

export const checkUserAccess = (
  permissions: Readonly<UserRole[]>,
  roles: Maybe<UserRole[]>,
): boolean => {
  // eslint-disable-next-line fp/no-let
  let grantAccess = false;
  permissions.forEach((permission) => {
    if (roles && roles.includes(permission)) {
      // eslint-disable-next-line fp/no-mutation
      grantAccess = true;
    }
  });
  return grantAccess;
};

export const stringToRole = (input: string): UserRole => {
  switch (input) {
    case "ADMIN":
      return UserRole.ADMIN;
    case "DEVELOPER":
      return UserRole.DEVELOPER;
    case "SUPPORT":
      return UserRole.SUPPORT;
    case "FINANCE":
      return UserRole.FINANCE;
    case "USER":
      return UserRole.USER;

    default:
      return UserRole.UNSPECIFIED;
  }
};

export const getUserRole = (roles: Readonly<UserRole[]> | undefined) => {
  if (roles) {
    if (roles.includes(UserRole.ADMIN)) {
      return UserRole.ADMIN;
    }
    if (roles.includes(UserRole.DEVELOPER)) {
      return UserRole.DEVELOPER;
    }
    if (roles.includes(UserRole.FINANCE)) {
      return UserRole.FINANCE;
    }
    if (roles.includes(UserRole.SUPPORT)) {
      return UserRole.SUPPORT;
    }
    if (roles.includes(UserRole.USER)) {
      return UserRole.USER;
    }
  }
  return UserRole.UNSPECIFIED;
};

export enum OpsRoles {
  APP_MGMT_MANAGER = "app_mgmt_manager",
  APP_MGMT_SUPPORT = "app_mgmt_support",
  APP_MGMT_EXTERNAL_AGENCY = "app_mgmt_external_agency",
  IDENTITY_MGMT_MANAGER = "identity_mgmt_manager",
  IDENTITY_MGMT_SUPPORT = "identity_mgmt_support",
  ACCOUNT_MGMT_MANAGER = "account_mgmt_manager",
  ACCOUNT_MGMT_SUPPORT = "account_mgmt_support",
  RISK_MGMT_MANAGER = "risk_mgmt_manager",
  RISK_MGMT_SUPPORT = "risk_mgmt_support",
  DISPUTES_MGMT_MANAGER = "disputes_mgmt_manager",
  DISPUTES_MGMT_SUPPORT = "disputes_mgmt_support",
  ALL = "all",
}
