"use client";

import { CommonAppContext } from "@bay1/data/CommonAppContext";
import React, { useContext } from "react";
import { OpsRoles } from "../helpers";

export const OpsOnly = ({
  roles,
  children,
}: Readonly<{
  roles: string[];
  children: React.ReactNode;
}>): JSX.Element => {
  const { activeOrganization: organization } = useContext(CommonAppContext);
  const roleMatches =
    roles.some((role) => organization?.agentRoles?.includes(role)) ||
    (roles.includes(OpsRoles.ALL) && organization?.isAdminUserAccessOrg);

  if (roleMatches) {
    return <>{children}</>;
  }

  return <></>;
};
