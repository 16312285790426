import type { TypePolicies } from "@apollo/client";
import { relayStylePagination } from "@apollo/client/utilities";

export const typePolicies: TypePolicies = {
  AccountHolderCardProductApplication: {
    fields: {
      financialAccounts: relayStylePagination(),
    },
  },
  CardProduct: {
    fields: {
      cardProductApplications: relayStylePagination(),
      groupOrders: relayStylePagination(),
      paymentCards: relayStylePagination(["filterBy"]),
      transactionEvents: relayStylePagination(["filterBy"]),
      integratorACHTransfers: relayStylePagination(["filterBy"]),
      externalACHTransfers: relayStylePagination(["filterBy"]),
    },
  },
  FinancialAccount: {
    fields: {
      financialAccountActivities: relayStylePagination(),
      integratorACHTransfers: relayStylePagination(),
      externalACHTransfers: relayStylePagination(),
      incomingScheduledTransfers: relayStylePagination(),
      paymentCards: relayStylePagination(),
      agentNotes: relayStylePagination(),
    },
  },
  NotificationEvent: {
    fields: {
      deliveryAttempts: relayStylePagination(),
    },
  },
  Organization: {
    fields: {
      spendRules: relayStylePagination(),
      users: relayStylePagination(),
      velocityRules: relayStylePagination(),
      webhookNotificationTargets: relayStylePagination(),
      apiKeys: relayStylePagination(),
    },
  },
  PaymentCard: {
    fields: {
      transactionEvents: relayStylePagination(["filterBy"]),
    },
  },
  PhysicalCardGroupOrder: {
    fields: {
      physicalPaymentCardOrders: relayStylePagination(),
    },
  },
  SpendRule: {
    fields: {
      revisions: relayStylePagination(),
    },
    keyFields: ["id", "version"],
  },
  USPersonAccountHolder: {
    fields: {
      paymentCards: relayStylePagination(),
      financialAccounts: relayStylePagination(),
      cardProductApplications: relayStylePagination(),
      agentNotes: relayStylePagination(),
    },
  },
  USBusinessAccountHolder: {
    fields: {
      paymentCards: relayStylePagination(),
      financialAccounts: relayStylePagination(),
      cardProductApplications: relayStylePagination(),
      agentNotes: relayStylePagination(),
    },
  },
  USBusinessProfile: {
    fields: {
      authorizedUsers: relayStylePagination(),
    },
  },
  USPersonAuthorizedUser: {
    fields: {
      associatedAccountHolders: relayStylePagination(),
      cardProductApplications: relayStylePagination(),
      paymentCards: relayStylePagination(),
    },
  },
  Ledger: {
    fields: {
      ledgerEntries: relayStylePagination(["filterBy"]),
    },
  },
  Query: {
    fields: {
      cardProducts: relayStylePagination(),
      businessAccountHolders: relayStylePagination(["filterBy"]),
      personAccountHolders: relayStylePagination(["filterBy"]),
      financialAccountActivities: relayStylePagination(),
      notificationEvents: relayStylePagination(),
    },
  },
  VelocityRule: {
    keyFields: ["id", "version"],
  },
  TransferRule: {
    keyFields: ["id", "version"],
  },
  WebhookNotificationTarget: {
    fields: {
      deliveryAttempts: relayStylePagination(),
    },
  },
};
